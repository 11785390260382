import * as i0 from "@angular/core";
import * as i1 from "./dip-gw-auth.service";
import * as i2 from "../core/authentiation/auth-provider";
import * as i3 from "@km-gw/km-gw-ts-angular/api/userProfileController.service";
import * as i4 from "@angular/router";
import * as i5 from "adal-angular8/adal8.service";
import * as i6 from "./dip-toast.service";
export class LoginService {
    constructor(authService, authProvider, apiUserProfileService, router, adalService, toast) {
        this.authService = authService;
        this.authProvider = authProvider;
        this.apiUserProfileService = apiUserProfileService;
        this.router = router;
        this.adalService = adalService;
        this.toast = toast;
    }
    login(token, userMode) {
        const azureToken = token ? token : this.adalService.getToken();
        function loginRoute(profile) {
            localStorage.setItem('empName', profile.data.nameEn);
            localStorage.setItem('empRole', profile.data.role);
            localStorage.setItem('empID', profile.data.empID);
            localStorage.setItem('empEmail', profile.data.email);
            localStorage.setItem('roles', JSON.stringify(profile.data.roles));
            if (token) {
                const returnUrl = localStorage.getItem('returnUrl');
                if (returnUrl != null) {
                    localStorage.removeItem('returnUrl');
                    console.log('re=' + returnUrl);
                    window.location.href = returnUrl;
                }
                else {
                    if (profile.data.role.includes('admin')) {
                        this.router.navigate(['/admin']);
                    }
                    else {
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/user']));
                    }
                }
            }
        }
        this.authService.login(azureToken, userMode)
            .subscribe((result) => {
            localStorage.setItem('pageSize', '10');
            localStorage.setItem('dip-gw-access-token', result.access_token);
            localStorage.setItem('dip-gw-refresh-token', result.refresh_token);
            localStorage.setItem('dip-gw-expires-in', result.expires_in.toString());
            localStorage.setItem('azure-token', azureToken);
            this.authProvider.onAppLoaded();
            this.apiUserProfileService.getUsingGET1().subscribe((profile) => {
                loginRoute.call(this, profile);
            }, (error) => {
                this.toast.showErr('Can not get profile: ' + error.error.message, 'ERROR');
            });
        }, () => {
            this.toast.showErr('User not found', 'ERROR');
            this.router.navigate(['/logout']);
        });
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.DipGwAuthService), i0.ɵɵinject(i2.AuthProvider), i0.ɵɵinject(i3.UserProfileControllerService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.Adal8Service), i0.ɵɵinject(i6.DipToastService)); }, token: LoginService, providedIn: "root" });
