import { NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import {
  BookOpen, ChevronDown, ChevronUp, ChevronLeft, ChevronRight, FileMinus, Plus,
  FilePlus, FolderMinus, FolderPlus, Folder, ArrowLeftCircle, PlusCircle, Heart,
  User, Shield, Lock, Search, Star, ArrowLeft, Layout, Paperclip, Trash, Edit, ZoomIn,
  ArrowRight, X, ChevronsLeft, ChevronsRight, Download, Trash2, ArrowDownCircle,
  Users, Calendar, LogIn, Circle, Book, Eye, Save, Unlock, LogOut, FileText, ArrowDown, ArrowUp,
  Settings, Key, Check, Share2, Mail, Upload, Move, Film, VideoOff, Video
} from 'angular-feather/icons';

const icons = {
  BookOpen,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  FileMinus,
  Plus,
  FilePlus,
  FolderMinus,
  FolderPlus,
  Folder,
  ArrowLeftCircle,
  PlusCircle,
  Heart,
  User,
  Shield,
  Lock,
  Search,
  Star,
  ArrowLeft,
  Layout,
  Paperclip,
  Video,
  VideoOff,
  Trash,
  Edit,
  ZoomIn,
  ArrowRight,
  X,
  ChevronsLeft,
  ChevronsRight,
  Download,
  Upload,
  Trash2,
  ArrowDownCircle,
  Users,
  Calendar,
  LogIn,
  Circle,
  Book,
  Eye,
  Save,
  Unlock,
  LogOut,
  FileText,
  ArrowDown,
  ArrowUp,
  Settings,
  Key,
  Check,
  Share2,
  Mail,
  Move,
  Film
};

@NgModule({
  declarations: [],
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})

export class IconsModule { }
