import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Adal8Service } from 'adal-angular8';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private adalService: Adal8Service
  ) { }
  @Input() isPublic: boolean;
  public profileName = localStorage.getItem('empName');
  ngOnInit() {
  }

  logout(): void {
    localStorage.clear();
    if (this.adalService.userInfo.authenticated) {
      this.adalService.logOut();
    } else  {
      this.router.navigate(['/logout']);
    }
  }

}
