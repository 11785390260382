import * as tslib_1 from "tslib";
export class AuthProvider {
    constructor(apiConfig, dipGwAuthService) {
        this.apiConfig = apiConfig;
        this.dipGwAuthService = dipGwAuthService;
    }
    onAppLoaded() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!this.apiConfig.apiKeys) {
                    this.apiConfig.apiKeys = {};
                }
                if (this.dipGwAuthService.token != null) {
                    this.apiConfig.apiKeys.Authorization = 'Bearer ' + this.dipGwAuthService.token;
                }
                resolve();
            }));
        });
    }
    setAuthorization(token) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.apiConfig.apiKeys.Authorization = 'Bearer ' + token;
                resolve();
            }));
        });
    }
}
