<!-- <div class="text-center">
    <div class="row">
      <div class="col text-muted">
        <small>ตลาดหลักทรัพย์แห่งประเทศไทย | สงวนลิขสิทธิ์</small>
      </div>
    </div>
    <div class="row">
      <div class="col text-muted">
        <small>เนื้อหาทั้งหมดบนเว็บไซต์นี้
          มีขึ้นเพื่อวัตถุประสงค์ในการให้ข้อมูลและเพื่อการศึกษาเท่านั้น ตลาดหลักทรัพย์ฯ
          มิได้ให้การรับรองและขอปฏิเสธต่อความรับผิดใด ๆ ในเว็บไซต์นี้
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col text-muted">
        <a href="https://www.set.or.th/th/terms-and-conditions.html">
          <small>ข้อตกลงและเงื่อนไขการใช้งานเว็บไซต์</small>
        </a> |
        <a href="https://www.set.or.th/th/privacy-policy.html">
          <small>นโยบายคุ้มครองข้อมูลส่วนบุคคล</small>
        </a> |
        <a href="https://www.set.or.th/th/cookies_policy.html">
          <small>นโยบายการใช้คุกกี้์</small>
        </a>
      </div>
    </div>
  </div> -->

  <div class="footer-copyright text-center py-1">
    <!-- © 2020 Copyright: -->
    <span class="text-uppercase">
      power by The Stock Exchange of Thailand
    </span>
  </div>