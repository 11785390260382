import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { config } from 'src/environments/config';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { LoginModel } from '../models/login.model';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})
export class DipGwAuthService {

  constructor(private http: HttpClient) { }

  get token(): string {
    return localStorage.getItem('dip-gw-access-token');
  }

  static errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'Server Error');
  }

  login(azureToken: string, userMode?: boolean): Observable<LoginModel> {
    let params = new HttpParams()
      .set('grant_type', 'password')
      .set('azure_token', azureToken);
    if (userMode) {
      params = new HttpParams()
      .set('grant_type', 'password')
      .set('azure_token', azureToken)
      .set('userMode', 'true');
    }
    const headers = new HttpHeaders({ Authorization: 'Basic ' + environment.basicAuthenticationCode });
    return this.http.post<LoginModel>(config.login, null, { headers, params })
      .pipe(
        map((data: any) => data)
        , catchError(DipGwAuthService.errorHandler));
  }

  loginWithRefreshToken(refreshToken: string): Observable<LoginModel> {
    const params = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken);
    const headers = new HttpHeaders({ Authorization: 'Basic ' + environment.basicAuthenticationCode });
    return this.http.post<LoginModel>(config.login, null, { headers, params })
      .pipe(
        map((data: any) => data)
        , catchError(DipGwAuthService.errorHandler));
  }

}
