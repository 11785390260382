import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './layout/public/login/login.component';
import { LogoutComponent } from './layout/public/logout/logout.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'admin', loadChildren: './layout/admin/admin.module#AdminModule' },
  { path: 'user', loadChildren: './layout/user/user.module#UserModule' },
  { path: 'watch', loadChildren: './layout/watch/watch.module#WatchModule' },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
