import { FileSizePipe } from './filesize/filesize.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyPipe } from './currency/currency.pipe';
import { ThaiDatePipe } from './thai-date/thai-date.pipe';

@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
      FileSizePipe,
      CurrencyPipe,
      ThaiDatePipe
   ],
   exports: [
      FileSizePipe,
      CurrencyPipe,
      ThaiDatePipe
   ],
   providers: [
      CurrencyPipe
   ]
})

export class PipesModule { }
