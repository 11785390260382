import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { config } from './../environments/config';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
  BASE_PATH,
  DocumentTypeControllerService
} from '@km-gw/km-gw-ts-angular';
import { AuthProvider } from './core/authentiation/auth-provider';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { PipesModule } from './pipes/pipes.module';
import { ToastrModule } from 'ngx-toastr';
import { registerLocaleData } from '@angular/common';
import localeTh from '@angular/common/locales/th';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { AppState } from './core/authentiation/app-state';
import { AuthInterceptor } from './core/authentiation/auth-interceptor';
import { LoginComponent } from './layout/public/login/login.component';
import { Adal8Service, Adal8HTTPService } from 'adal-angular8';
import { LogoutComponent } from './layout/public/logout/logout.component';
import { SharedModule } from './layout/shared/shared.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import {NgxFileDropModule} from 'ngx-file-drop';


let sharedApiModuleConfig: Configuration;

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
  };

  params.apiKeys = {
    Authorization: ''
  };

  if (sharedApiModuleConfig == null) {
    sharedApiModuleConfig = new Configuration(params);
  }
  return sharedApiModuleConfig;
}

registerLocaleData(localeTh);
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#FBAF34',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'chasing-dots',
  blur: 5,
  delay: 0,
  fgsColor: '#FBAF34',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'chasing-dots',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#FBAF34',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 500
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    HttpClientModule,
    ApiModule.forRoot(apiConfigFactory),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    UiSwitchModule,
    PipesModule,
    ToastrModule.forRoot(),
    NgxFileDropModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true,
      excludeRegexp: ['/api/protected/user/attachment/temp', '/api/protected/admin/employee-profile',
    '/api/protected/admin/app-user', '/api/protected/admin/attachment/migrate-in-progress-total'] }),
    SharedModule,
    ProgressbarModule.forRoot()
  ],
  providers: [
    AppState,
    AuthProvider,
    {
      provide: BASE_PATH, useValue: config.apiEndPoint
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      multi: true,
      deps: [AuthProvider]
    },
    DocumentTypeControllerService,
    { provide: LOCALE_ID, useValue: 'th-TH' },
    NgxUiLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory(router: Router, appState: AppState) {
        return new AuthInterceptor(router, appState);
      },
      useClass: AuthInterceptor,  // if error can be deleted
      multi: true,
      deps: [Router, AppState]
    },
    Adal8Service,
    {
      provide: Adal8HTTPService, useFactory: Adal8HTTPService.factory, deps: [HttpClient, Adal8Service]
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function init_app(authProvider: AuthProvider) {
  return () => authProvider.onAppLoaded();
}
