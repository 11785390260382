import { PipesModule } from './../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IconsModule } from 'src/app/module/icons/icons.module';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { PathHierarchyComponent } from './path-hierarchy/path-hierarchy.component';
import { RepositoryFolderModalComponent } from './repository/repository-folder-modal/repository-folder-modal.component';
import { RepositoryDeleteModalComponent } from './repository/repository-delete-modal/repository-delete-modal.component';
import { RepositoryDeleteWarningModalComponent
 } from './repository/repository-delete-warning-modal/repository-delete-warning-modal.component';
import { RepositoryTableComponent } from './repository/repository-table/repository-table.component';
import { RepositoryPaginationComponent } from './repository/repository-pagination/repository-pagination.component';
import { FileExtionsionSupportComponent } from './repository/file-extionsion-support/file-extionsion-support.component';
import { RepositoryImportComponent } from './repository/repository-import/repository-import.component';
import { RepositoryImportResultComponent } from './repository/repository-import-result/repository-import-result.component';
import { RepositoryMoveModalComponent } from './repository/repository-move-modal/repository-move-modal.component';


@NgModule({
    imports: [
        ReactiveFormsModule,
        IconsModule,
        CommonModule,
        RouterModule,
        PipesModule,
        FormsModule
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        MenuComponent,
        PathHierarchyComponent,
        RepositoryFolderModalComponent,
        RepositoryDeleteModalComponent,
        RepositoryDeleteWarningModalComponent,
        RepositoryTableComponent,
        RepositoryPaginationComponent,
        FileExtionsionSupportComponent,
        RepositoryImportComponent,
        RepositoryImportResultComponent,
        RepositoryMoveModalComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        MenuComponent,
        PathHierarchyComponent,
        RepositoryFolderModalComponent,
        RepositoryDeleteModalComponent,
        RepositoryDeleteWarningModalComponent,
        RepositoryTableComponent,
        RepositoryPaginationComponent,
        FileExtionsionSupportComponent,
        RepositoryImportComponent,
        RepositoryImportResultComponent,
        RepositoryMoveModalComponent
    ]
})

export class SharedModule { }
