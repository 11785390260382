<!-- <nav class="navbar  fixed-top bg-dark flex-md-nowrap p-0 shadow sticky-top"> -->
<nav class="navbar navbar-dark fixed-top  flex-md-nowrap p-0 shadow sticky-top" style="background-color: #fbb034; ">
  <a class="navbar-brand col-sm-2 col-md-3 mr-0 text-dark" href="https://www.set.or.th">
    <img class="circular--square  img-responsive my-0"
      style="width: 100%; height: 100%; max-width: 45px; max-height: 45px; padding: 5px;"
      src="assets/images/SETLogo50year.png">
    The Stock Exchange of Thailand</a>
  <ul *ngIf="!isPublic" class="navbar-nav px-3">
    <li class="nav-item text-nowrap">
      <span class="nav-link link text-dark">
        {{profileName}}
        <a (click)="logout()" class="ml-2">
          <i-feather class="icon-small text-dark float-right" name="log-out"></i-feather>
        </a>
      </span>
    </li>
  </ul>
</nav>
