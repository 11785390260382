/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i3 from "angular-feather";
import * as i4 from "@angular/common";
import * as i5 from "./header.component";
import * as i6 from "@angular/router";
import * as i7 from "adal-angular8/adal8.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ul", [["class", "navbar-nav px-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "li", [["class", "nav-item text-nowrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "span", [["class", "nav-link link text-dark"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["class", "ml-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "i-feather", [["class", "icon-small text-dark float-right"], ["name", "log-out"]], null, null, null, i2.View_FeatherComponent_0, i2.RenderType_FeatherComponent)), i1.ɵdid(6, 573440, null, 0, i3.FeatherComponent, [i1.ElementRef, i1.ChangeDetectorRef, i3.ɵa], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_1 = "log-out"; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileName; _ck(_v, 3, 0, currVal_0); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "nav", [["class", "navbar navbar-dark fixed-top  flex-md-nowrap p-0 shadow sticky-top"], ["style", "background-color: #fbb034; "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "navbar-brand col-sm-2 col-md-3 mr-0 text-dark"], ["href", "https://www.set.or.th"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "circular--square  img-responsive my-0"], ["src", "assets/images/SETLogo50year.png"], ["style", "width: 100%; height: 100%; max-width: 45px; max-height: 45px; padding: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The Stock Exchange of Thailand"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isPublic; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.HeaderComponent, [i6.Router, i7.Adal8Service], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i5.HeaderComponent, View_HeaderComponent_Host_0, { isPublic: "isPublic" }, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
