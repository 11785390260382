/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/header/header.component.ngfactory";
import * as i3 from "../../shared/header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "adal-angular8/adal8.service";
import * as i6 from "../../shared/footer/footer.component.ngfactory";
import * as i7 from "../../shared/footer/footer.component";
import * as i8 from "./login.component";
import * as i9 from "../../../service/login.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.Router, i5.Adal8Service], { isPublic: [0, "isPublic"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "container-fluid content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-footer", [], null, null, null, i6.View_FooterComponent_0, i6.RenderType_FooterComponent)), i1.ɵdid(5, 114688, null, 0, i7.FooterComponent, [], null, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i8.LoginComponent, [i5.Adal8Service, i9.LoginService, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i8.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
