import { config } from './../environments/config';
import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Adal8Service } from 'adal-angular8';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'KM';
  constructor(
    private ngxService: NgxUiLoaderService,
    private adalService: Adal8Service
    ) {
    this.adalService.init(config.adalConfig);
    this.adalService.handleWindowCallback();
  }

  ngOnInit() {
    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
    // Stop the foreground loading after 5s
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 1000);
  }
}
