import { config } from './../environments/config';
import { Configuration, ConfigurationParameters } from '@km-gw/km-gw-ts-angular';
import { AuthProvider } from './core/authentiation/auth-provider';
import { registerLocaleData } from '@angular/common';
import localeTh from '@angular/common/locales/th';
import { NgxUiLoaderConfig } from 'ngx-ui-loader';
import { Adal8HTTPService } from 'adal-angular8';
let sharedApiModuleConfig;
export function apiConfigFactory() {
    const params = {};
    params.apiKeys = {
        Authorization: ''
    };
    if (sharedApiModuleConfig == null) {
        sharedApiModuleConfig = new Configuration(params);
    }
    return sharedApiModuleConfig;
}
registerLocaleData(localeTh);
const ngxUiLoaderConfig = {
    bgsColor: '#FBAF34',
    bgsOpacity: 0.5,
    bgsPosition: 'bottom-right',
    bgsSize: 60,
    bgsType: 'chasing-dots',
    blur: 5,
    delay: 0,
    fgsColor: '#FBAF34',
    fgsPosition: 'center-center',
    fgsSize: 60,
    fgsType: 'chasing-dots',
    gap: 24,
    logoPosition: 'center-center',
    logoSize: 120,
    logoUrl: '',
    masterLoaderId: 'master',
    overlayBorderRadius: '0',
    overlayColor: 'rgba(40, 40, 40, 0.8)',
    pbColor: '#FBAF34',
    pbDirection: 'ltr',
    pbThickness: 3,
    hasProgressBar: true,
    text: '',
    textColor: '#FFFFFF',
    textPosition: 'center-center',
    maxTime: -1,
    minTime: 500
};
const ɵ0 = config.apiEndPoint, ɵ1 = Adal8HTTPService.factory;
export class AppModule {
}
export function init_app(authProvider) {
    return () => authProvider.onAppLoaded();
}
export { ɵ0, ɵ1 };
