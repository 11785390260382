import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber, unformat } from 'accounting';
import { SetOption } from 'src/app/models/option.model';

@Pipe({
  name: 'currency'
})
// HOW TO USE
// <p>{{ '20000' | currency }}</p> // 20,000.00
// <p>{{ '20000' | currency:4 }}</p> // 20,000.0000
// <p>{{ '20000' | currency:"THB" }}</p> // THB 20,000.00
// <p>{{ '20000' | currency:setOption }}</p> // THB 20.000,00

// TS File
// setOption = new SetOption();
// setOption.prefix = "THB"
// setOption.decimalPrecision = 2
// setOption.thousandsSeparator = "."
// setOption.decimalSeparator = ","
// setOption.prefixPosition = "right";

export class CurrencyPipe implements PipeTransform {

  private DECIMAL_SEPARATOR: string; // ตัวคั่นทศนิยม
  private THOUSANDS_SEPARATOR: string; // ตัวคั่นหลักพัน
  private DECIMAL_PRECISION: number; // ตำแหน่งทศนิยม
  private PREFIX_POSITION: string; // ตำแหน่งตัวอักษรพิเศษ
  constructor(
  ) {
    this.DECIMAL_PRECISION = 4;
    this.DECIMAL_SEPARATOR = '.';
    this.THOUSANDS_SEPARATOR = ',';
    this.PREFIX_POSITION = 'left';
  }

  transform(value: any, customCurrency?: SetOption): string {
    let prefix = '';
    if (customCurrency !== undefined) {
      // Check undefined
      if (typeof customCurrency !== 'object') {
        // Check not object
        if (typeof customCurrency === 'string') {
          prefix = customCurrency;
        }
        if (typeof customCurrency === 'number') {
          this.DECIMAL_PRECISION = customCurrency;
        }
      } else {
        // is obj
        if (customCurrency.prefix !== undefined) {
          prefix = customCurrency.prefix;
        }
        if (customCurrency.decimalPrecision !== undefined) {
          this.DECIMAL_PRECISION = customCurrency.decimalPrecision;
        }
        if (customCurrency.decimalSeparator !== undefined) {
          this.DECIMAL_SEPARATOR = customCurrency.decimalSeparator;
        }
        if (customCurrency.thousandsSeparator !== undefined) {
          this.THOUSANDS_SEPARATOR = customCurrency.thousandsSeparator;
        }
        if (customCurrency.prefixPosition) {
          this.PREFIX_POSITION = customCurrency.prefixPosition;
        }
      }
    } // end check

    if (value === '' || value === undefined) {
      return '';
    } else {
      let clearValue = value;
      if (typeof value === 'string') {
        clearValue = unformat(value.toString(), this.DECIMAL_SEPARATOR);
      }
      const toFormat = formatNumber(clearValue, {
        precision: this.DECIMAL_PRECISION,
        thousand: this.THOUSANDS_SEPARATOR,
        decimal: this.DECIMAL_SEPARATOR
      });

      if (prefix !== '') {
        return this.PREFIX_POSITION === 'left'
          ? prefix.concat(' ', toFormat)
          : toFormat.concat(' ', prefix);
      } else {
        return toFormat;
      }
    }
  }

  parse(value: string, customCurrency: any): string {
    if (value === '' || unformat(value, this.DECIMAL_SEPARATOR) === 0) {
      const clearValue = '';

      return clearValue;
    }

    return this.transform(value, customCurrency);
  }

}
