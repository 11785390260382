import {environment} from './environment';

export const config = {
  apiEndPoint: environment.apiBasePath,
  login: environment.apiBasePath + '/oauth/token',
  dipPortalCreateDocumentUrl: environment.dipPortalUrl + '/user/document-create?publicToken=',
  maxUploadFileSize: 5368709120,
  allowUploadMimeTypes: '.pdf, .xls, .xlsx, .doc, .docx, .png, .jpg, .jpeg, .gif, .mp4, .avi, .webm, .3gp, .3g2, .mpg, ' +
    '.mpeg, .m2v, .mpe, .mpv, .ogv, .ts, .mkv, .flv, .vob, .MTS, .M2TS, .TS, .mov, .qt, .wmv, .yuv, .rm, .rmvb, .viv,' +
    ' .asf, .amv, .m4v, .mxf, .aa, .aac, .aax, .aiff, .amr, .ape, .au, .awb, .dss, .dvf, .flac, .gsm, .m4a, .m4b, .m4p,' +
    ' .mp3, .mpc, .msv, .nmf, .ogg, .oga, .mogg, .opus, .ra, .rm, .raw, .rf64, .sln, .tta, .vox, .wav, .wma, .8svx, .cda',
  allowUploadImageMimeTypes: '.png, .jpg, .jpeg, .gif',
  allowUploadVDOMimeTypes: '.mp4, .avi, .webm, .3gp, .3g2, .mpg, ' +
    '.mpeg, .m2v, .mpe, .mpv, .ogv, .ts, .mkv, .flv, .vob, .MTS, .M2TS, .TS, .mov, .qt, .wmv, .yuv, .rm, .rmvb, .viv,' +
    ' .asf, .amv, .m4v, .mxf, .aa, .aac, .aax, .aiff, .amr, .ape, .au, .awb, .dss, .dvf, .flac, .gsm, .m4a, .m4b, .m4p,' +
    ' .mp3, .mpc, .msv, .nmf, .ogg, .oga, .mogg, .opus, .ra, .rm, .raw, .rf64, .sln, .tta, .vox, .wav, .wma, .8svx, .cda',
  adalConfig: {
    tenant: environment.tenant,
    clientId: environment.clientId,
    cacheLocation: 'localStorage',
    redirectUri: environment.origin + '/login',
    endpoints: {
      'https://graph.microsoft.com': 'graph',
    },
    postLogoutRedirectUri: environment.origin + '/logout'
  }
};
