import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
export class AppState {
    constructor(apiConfig, authService) {
        this.apiConfig = apiConfig;
        this.authService = authService;
    }
    loginRefreshToken() {
        return this.authService.loginWithRefreshToken(localStorage.getItem('dip-gw-refresh-token'));
    }
    setAuthorization(token) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            localStorage.setItem('dip-gw-access-token', token);
            const p = new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.apiConfig.apiKeys.Authorization = 'Bearer ' + token;
                resolve();
            }));
        });
    }
    logout() {
    }
}
