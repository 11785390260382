import { DipGwAuthService } from 'src/app/service/dip-gw-auth.service';
import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Configuration } from '@km-gw/km-gw-ts-angular/configuration';
import { LoginModel } from 'src/app/models/login.model';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class AppState {

    constructor(
        private apiConfig: Configuration,
        private authService: DipGwAuthService
    ) { }

    loginRefreshToken(): Observable<LoginModel> {
        return this.authService.loginWithRefreshToken(localStorage.getItem('dip-gw-refresh-token'));
    }

    async setAuthorization(token: string): Promise<void> {
      localStorage.setItem('dip-gw-access-token', token);
      const p = new Promise<void>(async (resolve, reject) => {
        this.apiConfig.apiKeys.Authorization = 'Bearer ' + token;
        resolve();
      });
    }

    logout() {

    }

}
