import { DipGwAuthService } from './../../service/dip-gw-auth.service';
import { Injectable } from '@angular/core';
import { Configuration } from '@km-gw/km-gw-ts-angular';

@Injectable()
export class AuthProvider {
    constructor(
        private apiConfig: Configuration,
        private dipGwAuthService: DipGwAuthService
    ) {

    }

    async onAppLoaded(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (!this.apiConfig.apiKeys) {
                this.apiConfig.apiKeys = {};
            }
            if (this.dipGwAuthService.token != null) {
              this.apiConfig.apiKeys.Authorization = 'Bearer ' + this.dipGwAuthService.token;
            }
            resolve();
        });
    }

    async setAuthorization(token: string): Promise<any> {
      return new Promise<any>(async (resolve, reject) => {
        this.apiConfig.apiKeys.Authorization = 'Bearer ' + token;
        resolve();
      });
  }

}
