import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Adal8Service } from 'adal-angular8';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private adalService: Adal8Service,
    private loginService: LoginService,
    private activeRoute: ActivatedRoute  // warning for test
  ) { }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      if (params.token) { // warning for test
        this.loginService.login(params.token);
      } else {
        const token = this.adalService.getToken();
        if (this.adalService.userInfo.authenticated && token) {
          localStorage.removeItem('dip-gw-access-token');
          localStorage.removeItem('dip-gw-refresh-token');
          localStorage.removeItem('dip-gw-expires-in');
          this.loginService.login(token);
        } else {
          this.adalService.login();
        }
      }
    });
  }
}
