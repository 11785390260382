/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/header/header.component.ngfactory";
import * as i3 from "../../shared/header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "adal-angular8/adal8.service";
import * as i6 from "@angular/common";
import * as i7 from "../../shared/footer/footer.component.ngfactory";
import * as i8 from "../../shared/footer/footer.component";
import * as i9 from "./logout.component";
var styles_LogoutComponent = [i0.styles];
var RenderType_LogoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.Router, i5.Adal8Service], { isPublic: [0, "isPublic"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "container-fluid content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You have been signed out"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You have been signed out from KM. "])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "a", [["class", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵted(-1, null, ["Click to login"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(13, 114688, null, 0, i8.FooterComponent, [], null, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_3 = _ck(_v, 9, 0, "/login"); _ck(_v, 8, 0, currVal_3); _ck(_v, 13, 0); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 8).target; var currVal_2 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_1, currVal_2); }); }
export function View_LogoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i1.ɵdid(1, 114688, null, 0, i9.LogoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = i1.ɵccf("app-logout", i9.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
