import { environment } from './../../environments/environment';
import { config } from 'src/environments/config';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DipGwAuthService {
    constructor(http) {
        this.http = http;
    }
    get token() {
        return localStorage.getItem('dip-gw-access-token');
    }
    static errorHandler(error) {
        return throwError(error.message || 'Server Error');
    }
    login(azureToken, userMode) {
        let params = new HttpParams()
            .set('grant_type', 'password')
            .set('azure_token', azureToken);
        if (userMode) {
            params = new HttpParams()
                .set('grant_type', 'password')
                .set('azure_token', azureToken)
                .set('userMode', 'true');
        }
        const headers = new HttpHeaders({ Authorization: 'Basic ' + environment.basicAuthenticationCode });
        return this.http.post(config.login, null, { headers, params })
            .pipe(map((data) => data), catchError(DipGwAuthService.errorHandler));
    }
    loginWithRefreshToken(refreshToken) {
        const params = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', refreshToken);
        const headers = new HttpHeaders({ Authorization: 'Basic ' + environment.basicAuthenticationCode });
        return this.http.post(config.login, null, { headers, params })
            .pipe(map((data) => data), catchError(DipGwAuthService.errorHandler));
    }
}
DipGwAuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DipGwAuthService_Factory() { return new DipGwAuthService(i0.ɵɵinject(i1.HttpClient)); }, token: DipGwAuthService, providedIn: "root" });
