import { config } from './../../../environments/config';
import { AuthProvider } from 'src/app/core/authentiation/auth-provider';
import { LoginModel } from './../../models/login.model';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable, Subject } from 'rxjs';
import { AppState } from './app-state';
import { retry, catchError, switchMap } from 'rxjs/operators';
import { Configuration } from '@km-gw/km-gw-ts-angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  apiConfig: Configuration;

  constructor(
    private router: Router,
    private appState: AppState
  ) { }

  authProvider: AuthProvider;

  private addAuthHeader(req, data: LoginModel) {
    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + data.access_token
      }
    });
  }

  private handleAuthError(req: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 && !err.message.includes('refresh')) {
      return this.appState.loginRefreshToken().pipe(
        switchMap((data) => {
          this.appState.setAuthorization(data.access_token);
          req = this.addAuthHeader(req, data);
          return next.handle(req);
        }),
        catchError((error) => {
          this.router.navigateByUrl('/login');
          return throwError(error);
        })
      );
    } else if (err.status === 403 || (err.status === 401 && err.message.includes('refresh'))) {
      localStorage.setItem('returnUrl', window.location.href);
      this.router.navigateByUrl('/login');
    } else {
      return throwError(err);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => this.handleAuthError(req, next, err))
    );
  }
}
