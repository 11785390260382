import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
export class DipToastService {
    constructor(toastr) {
        this.toastr = toastr;
    }
    showSuccess(message, title) {
        this.toastr.success(message, title);
    }
    showErr(message, title) {
        this.toastr.error(message, title);
    }
    showInfo(message, title) {
        this.toastr.info(message, title);
    }
}
DipToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DipToastService_Factory() { return new DipToastService(i0.ɵɵinject(i1.ToastrService)); }, token: DipToastService, providedIn: "root" });
