import {Injectable} from '@angular/core';
import {LoginModel} from '../models/login.model';
import {DipGwAuthService} from './dip-gw-auth.service';
import {AuthProvider} from '../core/authentiation/auth-provider';
import {APIResultEmployeeProfile, UserProfileControllerService} from '@km-gw/km-gw-ts-angular';
import {Router} from '@angular/router';
import {DipToastService} from './dip-toast.service';
import {Adal8Service} from 'adal-angular8';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private authService: DipGwAuthService,
    private authProvider: AuthProvider,
    private apiUserProfileService: UserProfileControllerService,
    private router: Router,
    private adalService: Adal8Service,
    private toast: DipToastService
  ) {
  }

  public login(token?: string, userMode?: boolean): void {
    const azureToken = token ? token : this.adalService.getToken();

    function loginRoute(profile: APIResultEmployeeProfile) {
      localStorage.setItem('empName', profile.data.nameEn);
      localStorage.setItem('empRole', profile.data.role);
      localStorage.setItem('empID', profile.data.empID);
      localStorage.setItem('empEmail', profile.data.email);
      localStorage.setItem('roles', JSON.stringify(profile.data.roles));
      if (token) {
        const returnUrl = localStorage.getItem('returnUrl');
        if (returnUrl != null) {
          localStorage.removeItem('returnUrl');
          console.log('re=' + returnUrl);
          window.location.href = returnUrl;
        } else  {
          if (profile.data.role.includes('admin')) {
            this.router.navigate(['/admin']);
          } else {
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['/user']));
          }
        }
      }
    }

    this.authService.login(azureToken, userMode)
      .subscribe(
        (result: LoginModel) => {
          localStorage.setItem('pageSize', '10');
          localStorage.setItem('dip-gw-access-token', result.access_token);
          localStorage.setItem('dip-gw-refresh-token', result.refresh_token);
          localStorage.setItem('dip-gw-expires-in', result.expires_in.toString());
          localStorage.setItem('azure-token', azureToken);
          this.authProvider.onAppLoaded();
          this.apiUserProfileService.getUsingGET1().subscribe(
            (profile) => {
              loginRoute.call(this, profile);
            },
            (error) => {
              this.toast.showErr('Can not get profile: ' + error.error.message, 'ERROR');
            }
          );
        },
        () => {
          this.toast.showErr('User not found', 'ERROR');
          this.router.navigate(['/logout']);
        });
  }

}
